import { get } from 'lodash/fp';
import { CLOSE_MENU } from './ui';
import { getUser, signIn, signInByLink, signOut } from '../../api/auth';

const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST';
const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';

const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

const FETCH_LOGOUT_REQUEST = 'FETCH_LOGOUT_REQUEST';
export const FETCH_LOGOUT_SUCCESS = 'FETCH_LOGOUT_SUCCESS';
const FETCH_LOGOUT_FAILURE = 'FETCH_LOGOUT_FAILURE';

const initialState = {
  isAuth: false,
  isLoading: false,
  isLogging: false,
  user: null,
};

const getters = {
  isAuth: get('isAuth'),
  isAuthPending: get('isLoading'),
  isLogging: get('isLogging'),
  user: get('user'),
  isConfirmedUser: get('user.EmailConfirmed'),
};

const mutations = {
  [FETCH_LOGIN_REQUEST](state) {
    state.isLogging = true;
  },
  [FETCH_USER_REQUEST](state) {
    state.isLoading = true;
  },
  [FETCH_LOGOUT_REQUEST](state) {
    state.isLoading = true;
  },
  [FETCH_USER_SUCCESS](state, data) {
    state.isAuth = true;
    state.isLoading = false;
    state.user = data;
  },
  [FETCH_LOGIN_SUCCESS](state, data) {
    state.isAuth = true;
    state.isLogging = false;
    state.user = data;
  },
  [FETCH_LOGIN_FAILURE](state) {
    state.isAuth = false;
    state.isLogging = false;
  },
  [FETCH_USER_FAILURE](state) {
    state.isAuth = false;
    state.isLoading = false;
  },
  [FETCH_LOGOUT_FAILURE](state) {
    state.isAuth = true;
    state.isLoading = false;
  },
  [FETCH_LOGOUT_SUCCESS](state) {
    state.isAuth = false;
    state.isLoading = false;
  },
};

const actions = {
  getUser: async ({ commit }) => {
    commit(FETCH_USER_REQUEST);
    try {
      const res = await getUser();
      commit(FETCH_USER_SUCCESS, res.data);
      return res;
    } catch (e) {
      commit(FETCH_USER_FAILURE);
      throw e;
    }
  },
  login: async ({ commit }, data) => {
    commit(FETCH_LOGIN_REQUEST);
    try {
      const res = await signIn(data);
      commit(FETCH_LOGIN_SUCCESS);
      window.location.replace('/');
      return res;
    } catch (e) {
      commit(FETCH_LOGIN_FAILURE);
      throw e;
    }
  },
  loginByMagicLink: async ({ commit }, magicguid) => {
    commit(FETCH_LOGIN_REQUEST);
    try {
      const res = await signInByLink(magicguid);
      commit(FETCH_LOGIN_SUCCESS, res.data);
      return res;
    } catch (e) {
      commit(FETCH_LOGIN_FAILURE);
      throw e;
    }
  },
  logout: async ({ commit }, redirect = '') => {
    commit(FETCH_LOGOUT_REQUEST);
    try {
      await signOut();
      commit(CLOSE_MENU);
      window.location.replace(`/?${redirect}`);
    } catch (e) {
      commit(FETCH_LOGOUT_FAILURE);
      throw e;
    }
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
