import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function removeChild() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

(function main(ELEMENT) {
  ELEMENT.matches =
    ELEMENT.matches ||
    ELEMENT.mozMatchesSelector ||
    ELEMENT.msMatchesSelector ||
    ELEMENT.oMatchesSelector ||
    ELEMENT.webkitMatchesSelector;
  ELEMENT.closest =
    ELEMENT.closest ||
    function closest(selector) {
      if (!this) return null;
      if (this.matches(selector)) return this;
      if (!this.parentElement) {
        return null;
      }
      return this.parentElement.closest(selector);
    };
})(Element.prototype);

const HAS_WINDOWS = typeof window !== 'undefined';
const HAS_NAVIGATOR = typeof navigator !== 'undefined';
const IS_TOUCH =
  HAS_WINDOWS &&
  ('ontouchstart' in window ||
    (HAS_NAVIGATOR && navigator.msMaxTouchPoints > 0));
const EVENTS = IS_TOUCH ? ['touchstart', 'click'] : ['click'];

/**
 * Get the dimensions of `$ele` ele.
 * @param {HTMLElement} $ele
 * @return {object} Object with `width` and `height` properties.
 */
function getSize($ele) {
  const $clone = $ele.cloneNode(true);
  const size = {};

  $clone.style.display = 'block';
  $clone.style.visibility = 'hidden';
  $clone.style.position = 'absolute';
  $ele.parentNode.insertBefore($clone, $ele);
  size.width = $clone.offsetWidth;
  size.height = $clone.offsetHeight;
  $clone.remove();

  return size;
}

/**
 * Set dropdown position.
 * @param {HTMLElement} $link - Html element will show the dropdown when it is pressed.
 * @param {HTMLElement} $dd - Dropdown html element.
 * @param {array} position - Dropdown position.
 * @param {array} margin - Dropdown offset.
 */
function setPosition($link, $dd, position, margin) {
  const refSize = getSize($link);
  const cornerPos = {};
  const outStyle = {};
  const origin = {};
  const [p1, p2, p3, p4] = position;
  const rect = $link.getBoundingClientRect();
  const refPos = { top: 0, left: 0 };
  const dpSize = getSize($dd);
  const parentPosition = document.defaultView.getComputedStyle(
    $link.offsetParent,
  ).position;

  switch (parentPosition.toLowerCase()) {
    case 'fixed':
      refPos.left = rect.left - $link.offsetParent.offsetLeft;
      refPos.top = rect.top - $link.offsetParent.offsetTop;
      break;
    case 'absolute': {
      const parentRect = $link.offsetParent.getBoundingClientRect();
      refPos.left = rect.left - parentRect.left;
      refPos.top = rect.top - parentRect.top;
      break;
    }
    default:
      refPos.left =
        rect.left - $link.offsetParent.offsetLeft + window.pageXOffset;
      refPos.top = rect.top - $link.offsetParent.offsetTop + window.pageYOffset;
  }

  cornerPos.left = refPos.left;
  switch (p1) {
    case 'center':
      cornerPos.left += refSize.width / 2;
      break;
    case 'right':
      cornerPos.left += refSize.width;
      break;
    default:
      cornerPos.left += refSize.width / 2;
      break;
  }

  cornerPos.top = refPos.top;
  switch (p2) {
    case 'center':
      cornerPos.top += refSize.height / 2;
      break;
    case 'bottom':
      cornerPos.top += refSize.height;
      break;
    default:
      cornerPos.top += refSize.height / 2;
      break;
  }

  switch (p3) {
    case 'left':
      outStyle.left = Math.round(cornerPos.left);
      origin.left = 'left';
      break;

    case 'center':
      outStyle.left = Math.round(cornerPos.left - dpSize.width / 2);
      origin.left = 'center';
      break;

    default:
      outStyle.left = Math.round(cornerPos.left - dpSize.width);
      origin.left = 'right';
  }

  switch (p4) {
    case 'top':
      outStyle.top = Math.round(cornerPos.top);
      origin.top = 'top';
      break;

    case 'center':
      outStyle.top = Math.round(cornerPos.top - dpSize.height / 2);
      origin.top = 'center';
      break;

    default:
      outStyle.top = Math.round(cornerPos.top - dpSize.height);
      origin.top = 'bottom';
  }
  const [offsetX = 0, offsetY = 5] = margin;
  outStyle.left = `${outStyle.left + offsetX}px`;
  outStyle.top = `${outStyle.top + offsetY}px`;
  outStyle.transformOrigin = `${origin.left} ${origin.top}`;
  outStyle.position = 'absolute';
  return outStyle;
}

const getWindow = () => window;

@Component({
  name: 'dropdown',
})
class Dropdown extends Vue {
  @Prop({ required: true, type: Boolean }) visible;

  @Prop({ type: Array, default: () => ['right', 'top', 'left', 'top'] })
  position;

  @Prop({ type: String, default: 'fade' }) animation;

  ddStyle = {};

  @Prop({ default: () => ({ x: 0, y: 0 }) }) offset;

  @Watch('visible')
  onSwitch(isVisible) {
    if (isVisible) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.setPosition();

    getWindow().addEventListener('resize', this.resizeEvent);

    setTimeout(
      () =>
        EVENTS.forEach((event) => {
          getWindow().addEventListener(event, this.clickOutEvent);
        }),

      10,
    );
  }

  close() {
    getWindow().removeEventListener('resize', this.resizeEvent);
    EVENTS.forEach((event) => {
      getWindow().removeEventListener(event, this.clickOutEvent);
    });
  }

  resizeEvent() {
    this.setPosition();
  }

  clickOutEvent(evt) {
    const $dd = this.$el.children[1];
    if (
      evt.target !== $dd &&
      !$dd.contains(evt.target) &&
      !evt.target.closest('.lang')
    ) {
      this.$emit('clickout', evt);
    }
  }

  setPosition() {
    const $link = this.$el.children[0];
    const $dd = this.$el.children[1];
    this.ddStyle = setPosition($link, $dd, this.position, [
      this.offset.x,
      this.offset.y,
    ]);
    this.$emit('positioned', this.ddStyle);
  }

  mounted() {
    if (this.visible) {
      this.open();
    }
  }

  destroyed() {
    getWindow().removeEventListener('resize', this.resizeEvent);
    EVENTS.forEach((event) => {
      getWindow().removeEventListener(event, this.clickOutEvent);
    });
  }
}

export default Dropdown;
