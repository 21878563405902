import { map, get, keyBy, compose, merge, concat } from 'lodash/fp';
import {
  addIssue,
  getIssues,
  getIssue,
  getNewMessageCount,
  getIssueCategories,
  addIssueWithoutBooking,
} from '../../api/support';

const FETCH_ISSUES_REQUEST = 'FETCH_ISSUES_REQUEST';
const FETCH_ISSUES_SUCCESS = 'FETCH_ISSUES_SUCCESS';
const FETCH_ISSUES_FAILURE = 'FETCH_ISSUES_FAILURE';

const FETCH_ISSUE_REQUEST = 'FETCH_ISSUE_REQUEST';
const FETCH_ISSUE_SUCCESS = 'FETCH_ISSUE_SUCCESS';
const FETCH_ISSUE_FAILURE = 'FETCH_ISSUE_FAILURE';

const ADD_ISSUE_SUCCESS = 'ADD_ISSUE_SUCCESS';
const FETCH_NEW_MESSAGE_COUNT_SUCCESS = 'FETCH_NEW_MESSAGE_COUNT_SUCCESS';
const FETCH_ISSUES_CATEGORIES_SUCCESS = 'FETCH_ISSUES_CATEGORIES_SUCCESS';

const MARK_READ = 'MARK_READ';

const initialState = {
  isLoading: false,
  allIds: [],
  byId: {},
  newMessageCount: 0,
  meta: {
    page: 1,
    totalPages: 1,
  },
  categories: [],
  isIssueLoading: false,
  currentIssue: null,
};

const getters = {
  issues: ({ allIds, byId }) => map((id) => byId[id], allIds),
  issuesLoading: get('isLoading'),
  issueLoading: get('isIssueLoading'),
  newMessageCount: get('newMessageCount'),
  issuesMeta: get('meta'),
  issueCategories: compose(
    map(({ id, name }) => ({ label: name, value: id })),
    get('categories'),
  ),
  issue: get('currentIssue'),
};

const mutations = {
  [FETCH_ISSUES_REQUEST](state) {
    state.isLoading = true;
  },
  [FETCH_ISSUES_FAILURE](state) {
    state.isLoading = false;
  },
  [FETCH_ISSUES_SUCCESS](state, { allIds, byId, meta }) {
    state.allIds = allIds;
    state.byId = byId;
    state.meta = meta;
    state.isLoading = false;
  },
  [ADD_ISSUE_SUCCESS](state, issue) {
    if (!state.allIds.includes(issue.id)) {
      state.allIds.unshift(issue.id);
      state.byId[issue.id] = issue;
    } else {
      state.byId[issue.id].lastMessage = issue.lastMessage;
    }
  },
  [FETCH_NEW_MESSAGE_COUNT_SUCCESS](state, newMessageCount) {
    state.newMessageCount = newMessageCount;
  },
  [FETCH_ISSUES_CATEGORIES_SUCCESS](state, categories) {
    state.categories = categories;
  },
  [FETCH_ISSUE_REQUEST](state) {
    state.isIssueLoading = true;
  },
  [FETCH_ISSUE_FAILURE](state) {
    state.isIssueLoading = false;
  },
  [FETCH_ISSUE_SUCCESS](state, issue) {
    state.currentIssue = issue;
    state.isIssueLoading = false;
  },
  [MARK_READ](state, issueId) {
    if (state.allIds.length) {
      state.byId[issueId].haveNewMessages = false;
      state.byId[issueId].newMessagesCount = 0;
    }
  },
};

const actions = {
  fetchIssues: async ({ commit, state }, data = {}) => {
    if (!data.silent) commit(FETCH_ISSUES_REQUEST);
    try {
      const res = await getIssues(data.page);

      const allIds =
        data.page && data.page > state.meta.page
          ? compose(concat(state.allIds), map('id'), get('data.issues'))(res)
          : map('id', res.data.issues);
      const byId =
        data.page && data.page > state.meta.page
          ? compose(merge(state.byId), keyBy('id'), get('data.issues'))(res)
          : keyBy('id', res.data.issues);
      const meta = {
        page: res.data.page,
        totalPages: res.data.totalPages,
      };
      commit(FETCH_ISSUES_SUCCESS, { allIds, byId, meta });
      return res;
    } catch (e) {
      commit(FETCH_ISSUES_FAILURE);
      throw e;
    }
  },
  addIssue: async ({ commit }, { withBooking, data }) => {
    const res = withBooking
      ? await addIssue(data)
      : await addIssueWithoutBooking(data);
    commit(ADD_ISSUE_SUCCESS, res.data);
    return res;
  },
  fetchNewMessageCount: async ({ commit }) => {
    const res = await getNewMessageCount();
    commit(FETCH_NEW_MESSAGE_COUNT_SUCCESS, res.data);
    return res;
  },
  fetchIssueCategories: async ({ commit }) => {
    const res = await getIssueCategories();
    commit(FETCH_ISSUES_CATEGORIES_SUCCESS, res.data);
    return res;
  },
  fetchIssue: async ({ commit }, id) => {
    commit(FETCH_ISSUE_REQUEST);
    try {
      const res = await getIssue(id);
      commit(FETCH_ISSUE_SUCCESS, res.data);
      return res;
    } catch (e) {
      commit(FETCH_ISSUE_FAILURE);
      throw e;
    }
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
