import api from './config';

export default function getSegments(params = {}) {
  return api('/FlightSegments', { params });
}

export function getScheduleChange(id) {
  const encodedId = encodeURIComponent(id);
  return api.get(`/ScheduleChanges/${encodedId}`);
}
