import Icon from '@/components/UI/Icon';
import size from '@/helpers/filters/size';
import css from './attach.module.scss';

const fileIcon = {
  jpg: 'jpg',
  jpeg: 'jpg',
  txt: 'txt',
  pdf: 'pdf',
  doc: 'doc',
  docx: 'doc',
  xls: 'xls',
  xlsx: 'xls',
  bmp: 'bmp',
  png: 'png',
  gif: 'gif',
};

const Attach = ({ props }) => {
  const { fileDownloadLink, name: fileName, fileSize, isClient } = props;
  const nameArr = fileName.split('.');
  const ext = nameArr.pop().toLowerCase();
  const name = nameArr.join('.');

  return (
    <a
      class={[css.attach, isClient && css.client]}
      href={fileDownloadLink}
      download
    >
      <div class={css.icon}>
        <Icon width="28" height="28" name={fileIcon[ext]} />
      </div>
      <div class={css.desc}>
        <div class={css.name}>{name}</div>
        <div class={css.size}>{size(fileSize)}</div>
      </div>
    </a>
  );
};

export default Attach;
