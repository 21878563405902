import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({
  inheritAttrs: false,
  name: 'app-input',
})
class Input extends Vue {
  @Prop({ type: String, default: '' }) value;

  @Prop({ type: String, default: '' }) className;

  @Prop({ type: String, default: '' }) name;

  @Prop({ type: String, default: '' }) label;

  @Getter errors;

  @Action cleanErrors;

  input($event) {
    this.$emit('input', $event.target.value);
    this.cleanErrors();
  }

  get error() {
    return this.errors[this.name];
  }

  get classes() {
    return ['input', this.className, { error: this.error }];
  }
}

export default Input;
