import qs from 'query-string';
import api from './config';

export function getBookings() {
  return api('/Bookings/page/1');
}

export function getBooking(id) {
  const encodedId = encodeURIComponent(id);
  return api(`/Bookings?bookingid=${encodedId}`);
}

export function getBookingByPnr({ pnr, email, MagicGuid }) {
  const query = qs.stringify({
    email,
    pnr,
    MagicGuid,
  });
  return api(`/Bookings/bypnrandemail?${query}`);
}
