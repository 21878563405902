import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LANGUAGE } from '@/constants';

@Component
class LanguageSelect extends Vue {
  @Prop() offset;

  @Getter supportedCultures;

  @Action setLocale;

  isOpen = false;

  get currentLang() {
    return LANGUAGE[this.$options.filters.lang(this.$i18n.locale)];
  }

  getLanguage(lang) {
    return LANGUAGE[this.$options.filters.lang(lang)];
  }

  getFlagIcon(lang) {
    return `${this.$options.filters.lang(lang)}-flag`;
  }

  async setLang(locale) {
    this.isOpen = false;
    await this.setLocale(locale);
    [this.$i18n.locale] = locale.split('-');
  }
}

export default LanguageSelect;
