import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { throttle, equals } from 'lodash/fp';
import LanguageSelect from '@/components/LanguageSelect';

@Component({
  components: {
    LanguageSelect,
  },
})
class Header extends Vue {
  @Getter booking;

  @Getter isAuth;

  @Getter logo;

  @Getter color;

  @Action openMenu;

  fixed = false;

  isLoginPage = equals('/login', window.location.pathname);

  isErrorPage = equals('/not-found', window.location.pathname);

  get isMenuHidden() {
    return this.isLoginPage || this.isErrorPage;
  }

  get pnr() {
    return this.booking.pnr;
  }

  @Watch('$route')
  onRouteChange() {
    this.isLoginPage = equals('/login', window.location.pathname);
    this.isErrorPage = equals('/not-found', window.location.pathname);
  }

  created() {
    const sk = window.scrollY;
    const width = window.innerWidth;
    this.fixed = sk > (width > 768 ? 85 : 50);
    document.addEventListener('scroll', this.onScroll());
  }

  onScroll() {
    return throttle(200, () => {
      const sk = window.scrollY;
      const width = window.innerWidth;
      this.fixed = sk > (width > 768 ? 85 : 50);
    });
  }
}

export default Header;
