import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import LanguageSelect from '@/components/LanguageSelect';
import { KEY_CODE } from '../../constants';
import Booking from '../BookingItem';

@Component({
  components: {
    Booking,
    LanguageSelect,
  },
})
class Sidebar extends Vue {
  @Getter bookings;

  @Getter user;

  @Getter color;

  @Getter isMenuOpen;

  @Action closeMenu;

  @Action logout;

  onLogout() {
    this.logout();
  }

  onEscKeyPress(evt) {
    if (evt.keyCode === KEY_CODE.ESC) {
      this.closeMenu();
    }
  }

  mounted() {
    document.addEventListener('keyup', this.onEscKeyPress);
  }

  beforeDestroy() {
    document.addEventListener('keyup', this.onEscKeyPress);
  }
}

export default Sidebar;
