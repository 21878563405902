import memoize from 'fast-memoize';
import { equals } from 'lodash/fp';

function formatBytes(bytes, decimals) {
  if (!bytes && !equals(0, +bytes)) {
    return false;
  }
  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(+bytes) / Math.log(k));

  return `${parseFloat((+bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export default memoize(formatBytes);
