import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
class Icon extends Vue {
  @Prop() width;

  @Prop(String) height;

  @Prop(String) className;

  @Prop({ type: String, required: true }) name;
}

export default Icon;
