import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { KEY_CODE } from '@/constants';
import PopularItem from './PopularItem';

@Component({
  name: 'popular-questions',
  components: {
    PopularItem,
  },
})
class PopularQuestions extends Vue {
  @Prop() openFaq;

  @Getter color;

  @Getter faqs;

  @Getter isFaqsLoading;

  @Action closeQuestions;

  onEscKeyPress(evt) {
    if (evt.keyCode === KEY_CODE.ESC) {
      this.closeQuestions();
    }
  }

  mounted() {
    document.addEventListener('keyup', this.onEscKeyPress);
  }

  beforeDestroy() {
    document.removeEventListener('keyup', this.onEscKeyPress);
  }
}

export default PopularQuestions;
