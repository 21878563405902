import api from './config';

export function getSettings() {
  return api('/Settings/');
}

export function getLocale() {
  return api('/Account/contextculture');
}

export function updateLocale(locale) {
  return api.post('/Account/contextculture', locale);
}
