import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { askAccessByPinCode } from '@/api/auth';
import { KEY_CODE, UPDATE_DELAY } from '@/constants';
import QuestionItem from '../../QuestionItem';
import ConfirmCode from '../ConfirmCode';

@Component({
  components: {
    QuestionItem,
    ConfirmCode,
  },
})
class MyQuestions extends Vue {
  @Prop() openConversation;

  @Prop() onNewQuestion;

  @Getter issues;

  @Getter issuesMeta;

  @Getter issuesLoading;

  @Getter color;

  @Action fetchIssues;

  @Action closeQuestions;

  @Getter booking;

  @Getter isConfirmedUser;

  @Getter user;

  @Getter confirmationRequiredWithBooking;

  timeout = null;

  isSending = false;

  confirmOpen = false;

  get mustEnterCode() {
    return (
      this.booking.haveSupportIssue &&
      !this.isConfirmedUser &&
      this.confirmationRequiredWithBooking &&
      !this.confirmOpen
    );
  }

  async requestPinCode() {
    this.isSending = true;
    try {
      await askAccessByPinCode({ email: this.user.ContextEmail });
      this.confirmOpen = true;
    } finally {
      this.isSending = false;
    }
  }

  async handleCloseConfirm() {
    await this.fetchIssues({ page: 1, silent: !!this.issues.length });
    this.confirmOpen = false;
  }

  get askNewQuestion() {
    return (
      !this.issues.length &&
      !this.issuesLoading &&
      !this.mustEnterCode &&
      !this.confirmOpen
    );
  }

  pollIssues() {
    this.timeout = setTimeout(async () => {
      try {
        await this.fetchIssues({ page: 1, silent: true });
      } finally {
        this.pollIssues();
      }
    }, UPDATE_DELAY);
  }

  onEscKeyPress(evt) {
    if (evt.keyCode === KEY_CODE.ESC) {
      this.closeQuestions();
    }
  }

  mounted() {
    this.fetchIssues({ page: 1, silent: !!this.issues.length });
    this.pollIssues();
    document.addEventListener('keyup', this.onEscKeyPress);
  }

  beforeDestroy() {
    clearTimeout(this.timeout);
    document.removeEventListener('keyup', this.onEscKeyPress);
  }
}

export default MyQuestions;
