import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { getOr, each, equals, indexOf, remove, some } from 'lodash/fp';
import { markReadedIssue } from '@/api/support';
import { KEY_CODE, UPDATE_DELAY, VALID_FILE_EXTENSIONS } from '@/constants';
import Message from './Message';

@Component({
  components: {
    Message,
  },
})
class Conversation extends Vue {
  @Prop(Function) close;

  @Prop(String) issueId;

  @Getter color;

  @Getter settings;

  @Action fetchIssue;

  @Action fetchMessages;

  @Action addMessage;

  @Action rateMessage;

  @Action fetchNewMessageCount;

  @Action closeQuestions;

  @Getter issue;

  @Getter issueLoading;

  @Getter messagesLoading;

  @Getter messages;

  @Getter isMessageRating;

  @Getter newMessageCount;

  @Mutation('MARK_READ') markRead;

  text = '';

  rateText = '';

  isSending = false;

  filesToUpload = [];

  formData = new FormData();

  fileError = '';

  messageRate = null;

  timeout = null;

  chatScrollEnabled = true;

  get isLoading() {
    return this.issueLoading || this.messagesLoading;
  }

  hideError() {
    this.fileError = '';
  }

  requestMessages() {
    this.timeout = setTimeout(async () => {
      try {
        await this.fetchMessages({
          id: this.issueId,
          silent: true,
        });
        await markReadedIssue(this.issueId);
      } finally {
        this.requestMessages();
      }
    }, UPDATE_DELAY);
  }

  onEscKeyPress(evt) {
    if (evt.keyCode === KEY_CODE.ESC) {
      if (this.messageRate) {
        this.closeRatePopup();
        return;
      }
      this.closeQuestions();
    }
  }

  async mounted() {
    try {
      await this.fetchIssue(this.issueId);
    } catch (e) {
      this.close();
    }
    await this.fetchMessages({ id: this.issueId });
    await markReadedIssue(this.issueId);
    this.markRead(this.issueId);
    await this.requestMessages();
    this.fetchNewMessageCount();
    document.addEventListener('keyup', this.onEscKeyPress);
    const lastMessage = this.messages[this.messages.length - 1];
    const lastEl = document.getElementById(lastMessage.id);
    const offset = -(window.innerHeight - 325) / 2;
    this.$smoothScroll({
      container: lastEl?.parentNode,
      scrollTo: lastEl,
      duration: 0,
      offset,
    });
    this.$nextTick(() => {
      this.chatScrollEnabled = true;
    });
  }

  beforeDestroy() {
    clearTimeout(this.timeout);
    document.removeEventListener('keyup', this.onEscKeyPress);
  }

  get booking() {
    return getOr(null, 'booking')(this.issue);
  }

  filesChange(e) {
    const fileList = e.target.files;
    this.fileError = '';
    this.filesToUpload = [];
    each((file) => {
      const item = {
        name: file.name,
        file,
      };
      const ext = file.name.split('.').pop().toLowerCase();

      if (equals(-1, indexOf(ext, VALID_FILE_EXTENSIONS))) {
        this.fileError = this.$t('invalid_file_type_only_allowed', {
          0: VALID_FILE_EXTENSIONS.join(', ').toUpperCase(),
        });
        return;
      }
      if (file.size > this.settings.attachFileMaxSizeMb * 1e6) {
        this.fileError = `${this.$t('the_maximum_allowed_file_size_is')} ${
          this.settings.attachFileMaxSizeMb
        }Mb`;
        return;
      }
      if (!some(item, this.filesToUpload)) {
        this.filesToUpload.push(item);
      }
    }, fileList);
    e.target.value = '';
  }

  removeFile(item) {
    this.filesToUpload = remove(item, this.filesToUpload);
    this.$refs.file.value = '';
  }

  async onSend() {
    const { text, issue } = this;
    if (!text && !this.filesToUpload.length) return;
    this.isSending = true;
    this.formData.append(
      'message',
      this.text.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
    );
    this.formData.append('issueid', issue.id);
    each(({ file, name }) => {
      this.formData.append('attachments', file, name);
    }, this.filesToUpload);
    try {
      await this.addMessage(this.formData);
      this.formData = new FormData();
      this.text = '';
      this.filesToUpload = [];
    } catch (e) {
      this.fileError = `${this.$t('the_maximum_allowed_file_size_is')} ${
        this.settings.attachFileMaxSizeMb
      }Mb`;
      throw e;
    } finally {
      this.isSending = false;
    }
  }

  openRatePopup(messageid, goodrate) {
    this.rateMessage({ data: { messageid, goodrate }, showLoader: false });
    this.messageRate = { messageid, goodrate };
  }

  closeRatePopup() {
    this.messageRate = null;
  }

  async sendRate() {
    await this.rateMessage({
      data: { ...this.messageRate, message: this.rateText },
      showLoader: true,
    });
    this.rateText = '';
    this.closeRatePopup();
  }
}

export default Conversation;
