import { get } from 'lodash/fp';
import { fetchFaqTopics, fetchFaqTopic } from '@/api/faq';

const FETCH_FAQS_REQUEST = 'FETCH_FAQS_REQUEST';
const FETCH_FAQS_SUCCESS = 'FETCH_FAQS_SUCCESS';
const FETCH_FAQS_FAILURE = 'FETCH_FAQS_FAILURE';
const FETCH_FAQ_REQUEST = 'FETCH_FAQ_REQUEST';
const FETCH_FAQ_SUCCESS = 'FETCH_FAQ_SUCCESS';
const FETCH_FAQ_FAILURE = 'FETCH_FAQ_FAILURE';

const initialState = {
  isFaqsLoading: true,
  isFaqLoading: true,
  items: [],
  current: null,
};

const getters = {
  faqs: get('items'),
  isFaqsLoading: get('isFaqsLoading'),
  isFaqLoading: get('isFaqLoading'),
  openedFaq: get('current'),
};

const mutations = {
  [FETCH_FAQ_REQUEST](state) {
    state.isFaqLoading = true;
  },
  [FETCH_FAQ_SUCCESS](state, faq) {
    state.current = faq;
    state.isFaqLoading = false;
  },
  [FETCH_FAQS_REQUEST](state) {
    state.isFaqsLoading = true;
  },
  [FETCH_FAQS_FAILURE](state) {
    state.isFaqsLoading = false;
  },
  [FETCH_FAQ_FAILURE](state) {
    state.isFaqLoading = false;
  },
  [FETCH_FAQS_SUCCESS](state, items) {
    state.items = items;
    state.isFaqsLoading = false;
  },
};

const actions = {
  fetchFaqs: async ({ commit }, bookingId) => {
    commit(FETCH_FAQS_REQUEST);
    try {
      const res = await fetchFaqTopics(bookingId);
      commit(FETCH_FAQS_SUCCESS, res.data);
      return res;
    } catch (e) {
      commit(FETCH_FAQS_FAILURE);
      throw e;
    }
  },
  fetchFaq: async ({ commit }, { id, bookingId }) => {
    commit(FETCH_FAQ_REQUEST);
    try {
      const res = await fetchFaqTopic(id, bookingId);
      commit(FETCH_FAQ_SUCCESS, res.data);
      return res;
    } catch (e) {
      commit(FETCH_FAQ_FAILURE);
      throw e;
    }
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
