import api from './config';

export function signIn(data) {
  return api.post('/Account/signin', data);
}

export function getUser() {
  return api('/Account/signin');
}

export function signOut() {
  return api.post('/Account/signout');
}

export function changeContext(email) {
  return api.post(`/Account/changecontext?email=${email}`);
}

export function askAccessByPinCode(data) {
  return api.post(`/Account/askaccessbypincode`, data);
}

export function askAccessByLink(data) {
  return api.post(`/Account/askaccessbylink`, data);
}

export function signInByLink(magicguid) {
  return api.post(`/Account/signinbylink`, { magicguid });
}

export function signInByPinCode(data) {
  return api.post(`/Account/signinbypincode`, data);
}
