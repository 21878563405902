import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component
class Booking extends Vue {
  @Prop({ required: true }) booking;

  @Prop(Number) idx;

  @Getter color;

  get classes() {
    const { $route, booking, color } = this;
    const { query } = $route;
    const { pnr } = booking;
    return ['booking', query.pnr === pnr && 'router-link-active', color];
  }

  get pnrEmail() {
    return this.$route.query.email;
  }

  get date() {
    const { departureDateTime, returnDateTime } = this.booking;
    return [departureDateTime, returnDateTime];
  }
}

export default Booking;
