import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'popular-item',
})
class PopularItem extends Vue {
  @Getter color;

  onClick(e) {
    this.$emit('click', e);
  }
}

export default PopularItem;
