import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import i18n, { dateLocales } from '../../i18n';

export default function formatDate(date, formatStr) {
  if (!date) return '';
  return format(parseISO(date), formatStr, {
    locale: dateLocales[i18n.locale],
  });
}
