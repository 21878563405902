import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { KEY_CODE } from '@/constants';

@Component
class PopularQuestion extends Vue {
  @Prop(Function) close;

  @Prop(String) faqId;

  @Getter color;

  @Action fetchFaq;

  @Getter openedFaq;

  @Getter isFaqLoading;

  @Getter booking;

  @Action closeQuestions;

  fixed = false;

  onScroll(e) {
    this.fixed = e.target.scrollTop;
  }

  onEscKeyPress(evt) {
    if (evt.keyCode === KEY_CODE.ESC) {
      this.closeQuestions();
    }
  }

  mounted() {
    this.fetchFaq({ id: this.faqId, bookingId: this.booking.id });
    document.addEventListener('keyup', this.onEscKeyPress);
  }

  beforeDestroy() {
    document.removeEventListener('keyup', this.onEscKeyPress);
  }
}

export default PopularQuestion;
