import { Component, Vue, Prop } from 'vue-property-decorator';
import { equals, contains } from 'lodash/fp';
import { Getter } from 'vuex-class';

@Component({
  name: 'question-item',
})
class QuestionItem extends Vue {
  @Prop() question;

  @Getter color;

  @Getter newMessageCount;

  get booking() {
    return this.question.booking;
  }

  get lastMessage() {
    const { body } = this.question.lastMessage.data;
    if (!body) return '';
    return body.replace(/<br ?\/?>/g, '\n').replace(/<([^>]+)>/gis, '');
  }

  get isClient() {
    return equals('Client', this.question.lastMessage.sourceHistoryItem);
  }

  get date() {
    const { departureDateTime, returnDateTime } = this.booking;
    return [departureDateTime, returnDateTime];
  }

  get isClosed() {
    return contains('Closed', this.question.status);
  }
}

export default QuestionItem;
