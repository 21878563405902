import { equals, get, includes } from 'lodash/fp';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { EXCLUDE_KEYS } from '@/constants';
import { signInByPinCode } from '@/api/auth';

@Component
class ConfirmCode extends Vue {
  @Action getUser;

  @Prop() email;

  @Prop() mustEnterCode;

  pincode = '';

  codeError = false;

  isSending = false;

  // eslint-disable-next-line class-methods-use-this
  onPincodeChange($event) {
    const isPaste =
      equals('v', $event.key) && ($event.metaKey || $event.ctrlKey);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN($event.key) && !includes($event.key, EXCLUDE_KEYS) && !isPaste) {
      $event.preventDefault();
    }
  }

  onPincodePaste($event) {
    this.codeError = '';
    // eslint-disable-next-line no-restricted-globals
    if (isNaN($event.target.value)) {
      this.pincode = '';
      $event.preventDefault();
    }
  }

  async confirmCode() {
    this.isSending = true;
    try {
      await signInByPinCode({ pincode: this.pincode, email: this.email });
      this.getUser();
      this.$emit('close');
    } catch (e) {
      if (equals('Wrong pincode', get('response.data')(e))) {
        this.codeError = true;
      }
    } finally {
      this.isSending = false;
    }
  }
}

export default ConfirmCode;
