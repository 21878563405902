import { map, get, keyBy, getOr } from 'lodash/fp';
import getSegments from '../../api/flightSegments';
import { FETCH_LOGOUT_SUCCESS } from './auth';

const FETCH_SEGMENTS_REQUEST = 'FETCH_SEGMENTS_REQUEST';
const FETCH_SEGMENTS_SUCCESS = 'FETCH_SEGMENTS_SUCCESS';
const FETCH_SEGMENTS_FAILURE = 'FETCH_SEGMENTS_FAILURE';

const HIDE_SEGMENTS_LOADER = 'HIDE_SEGMENTS_LOADER';

const initialState = {
  isLoading: true,
  allIds: [],
  byId: {},
  error: '',
};

const getters = {
  segments: ({ allIds, byId }) => map((id) => byId[id], allIds),
  segmentsLoading: get('isLoading'),
  segmentsError: get('error'),
};

const mutations = {
  [FETCH_SEGMENTS_REQUEST](state) {
    state.isLoading = true;
    state.error = '';
  },
  [FETCH_SEGMENTS_SUCCESS](state, { allIds, byId }) {
    state.allIds = allIds;
    state.byId = byId;
    state.isLoading = false;
  },
  [FETCH_SEGMENTS_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [HIDE_SEGMENTS_LOADER](state) {
    state.isLoading = false;
  },
  [FETCH_LOGOUT_SUCCESS](state) {
    state.isLoading = false;
    state.allIds = [];
    state.byId = {};
  },
};

const actions = {
  fetchSegments: async ({ commit }, params) => {
    commit(FETCH_SEGMENTS_REQUEST);
    try {
      const res = await getSegments(params);
      const allIds = map('id', res.data);
      const byId = keyBy('id', res.data);
      commit(FETCH_SEGMENTS_SUCCESS, { allIds, byId });
      return res;
    } catch (e) {
      commit(FETCH_SEGMENTS_FAILURE, getOr(500, 'response.status')(e));
      throw e;
    }
  },
  hideSegmentsLoader: ({ commit }) => commit(HIDE_SEGMENTS_LOADER),
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
