import VueI18n from 'vue-i18n';
import Vue from 'vue';
import enDate from 'date-fns/locale/en-GB';
import svDate from 'date-fns/locale/sv';
import { LOCALES } from '@/constants';
import en from './en.json';
import sv from './sv.json';

// VueI18n.prototype.getChoiceIndex = function getChoiceIndex(
//   choice,
//   choicesLength,
// ) {
//   // this === VueI18n instance, so the locale property also exists here
//   if (this.locale !== 'ru') {
//     // proceed to the default implementation
//   }
//
//   if (choice === 0) {
//     return 0;
//   }
//
//   const teen = choice > 10 && choice < 20;
//   const endsWithOne = choice % 10 === 1;
//
//   if (!teen && endsWithOne) {
//     return 1;
//   }
//
//   if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
//     return 2;
//   }
//
//   return choicesLength < 4 ? 2 : 3;
// };

Vue.use(VueI18n);

export const dateLocales = {
  en: enDate,
  sv: svDate,
};

const browserLocale = localStorage.getItem('locale');

const i18n = new VueI18n({
  // eslint-disable-next-line no-nested-ternary
  locale: browserLocale
    ? browserLocale.split('-')[0]
    : LOCALES.has(navigator.language)
    ? navigator.language
    : 'en',
  messages: { en, sv },
  fallbackLocale: 'en',
});

export default i18n;
