var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isLoading)?_c('Spinner'):(!_vm.isLoading && _vm.issue)?_c('div',{staticClass:"conversation"},[_c('div',{class:['header', _vm.color]},[_c('div',{staticClass:"top"},[_c('button',{staticClass:"backBtn",on:{"click":_vm.close}},[_c('span',[_c('Icon',{attrs:{"name":"arrow-left","width":"10","height":"16"}})],1)]),_c('span',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('my_questions')))]),_c('div',{staticClass:"print"},[_c('Icon',{attrs:{"width":"11","height":"14","name":"print2"}}),_vm._v(" "+_vm._s(_vm.$t('print'))+" ")],1)]),(_vm.booking)?_c('div',{staticClass:"booking"},[_c('div',{staticClass:"direction"},[_c('span',[_vm._v(_vm._s(_vm.booking.departure))]),_c('span',{staticClass:"arrow"},[(_vm.booking.routeType === 1)?_c('Icon',{attrs:{"name":"direction-sm","width":"11","height":"11"}}):_c('Icon',{attrs:{"name":"arrow-right","width":"10","height":"10"}})],1),_c('span',[_vm._v(_vm._s(_vm.booking.destination))])]),_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm._f("bookingDate")([_vm.booking.departureDateTime, _vm.booking.returnDateTime]))+" ")])]):_c('div',{staticClass:"booking"},[_vm._v(_vm._s(_vm.$t('question_without_booking')))]),_c('div',{staticClass:"topic"},[_vm._v(_vm._s(_vm.issue.issueCategoryName))])]),_c('div',{directives:[{name:"bar",rawName:"v-bar",value:({ preventParentScroll: true }),expression:"{ preventParentScroll: true }"}],class:['messages', _vm.color]},[_c('ul',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({
        enabled: _vm.chatScrollEnabled,
        always: false,
        smooth: true,
        scrollonremoved: true,
      }),expression:"{\n        enabled: chatScrollEnabled,\n        always: false,\n        smooth: true,\n        scrollonremoved: true,\n      }"}],ref:"list"},_vm._l((_vm.messages),function(message){return _c('Message',{key:message.id,attrs:{"message":message,"id":message.id,"openRatePopup":_vm.openRatePopup}})}),1)]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"attachFiles"},_vm._l((_vm.filesToUpload),function(file){return _c('div',{key:file.name},[_c('span',[_vm._v(" "+_vm._s(file.name)+" "),_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.removeFile(file)}}},[_c('Icon',{attrs:{"width":"12","height":"12","name":"close3"}})],1)])])}),0),_c('div',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],attrs:{"rows":"5","className":"textInput","placeholder":_vm.$t('Click here to type')},domProps:{"value":(_vm.text)},on:{"input":function($event){if($event.target.composing)return;_vm.text=$event.target.value}}}),_c('div',{staticClass:"btns"},[_c('label',{staticClass:"attachInput"},[(_vm.fileError)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideError),expression:"hideError"}],staticClass:"attachError"},[_vm._v(" "+_vm._s(_vm.fileError)+" ")]):_vm._e(),_c('input',{ref:"file",attrs:{"type":"file","accept":"image/jpeg,image/png,image/gif,image/bmp,application/pdf"},on:{"change":_vm.filesChange}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({
              content: _vm.$t('attach_file'),
              delay: { show: 500 },
            }),expression:"{\n              content: $t('attach_file'),\n              delay: { show: 500 },\n            }",modifiers:{"top":true}}]},[_c('Icon',{attrs:{"name":"attach","width":"22","height":"22"}})],1)]),(_vm.isSending)?_c('Spinner',{attrs:{"type":"inner"}}):_c('app-button',{attrs:{"theme":"secondary","icon":true,"size":"md","disabled":!_vm.text && !_vm.filesToUpload.length},on:{"click":_vm.onSend}},[_c('Icon',{attrs:{"name":"plane2","width":"21","height":"21"}})],1)],1)])]),(_vm.messageRate)?_c('div',{staticClass:"ratePopup"},[_c('div',{class:['ratePopup__inner', { goodRate: _vm.messageRate.goodrate }]},[_c('button',{staticClass:"ratePopup__close",on:{"click":_vm.closeRatePopup}},[_c('Icon',{attrs:{"name":"close","width":"19","height":"19"}})],1),_c('label',[_c('b',[_vm._v(_vm._s(_vm.$t('leave_your_comments')))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.rateText),expression:"rateText"}],attrs:{"rows":"6"},domProps:{"value":(_vm.rateText)},on:{"input":function($event){if($event.target.composing)return;_vm.rateText=$event.target.value}}})]),_c('app-button',{attrs:{"theme":"secondary","isLoading":_vm.isMessageRating,"disabled":!_vm.rateText},on:{"click":_vm.sendRate}},[_vm._v(_vm._s(_vm.$t('send')))]),_c('app-button',{attrs:{"theme":"primary"},on:{"click":_vm.closeRatePopup}},[_vm._v(_vm._s(_vm.$t('not_now')))])],1)]):_vm._e()]):(!_vm.isLoading && !_vm.issue)?_c('div',{staticClass:"error"},[_c('Icon',{attrs:{"name":"sad","width":"80","height":"34"}}),_vm._v(" No issue was found ")],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }