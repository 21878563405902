import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { equals } from 'lodash/fp';
import formatDate from '@/helpers/filters/dateTime';
import Attach from './Attach.jsx';

@Component({
  components: {
    Attach,
  },
})
class Message extends Vue {
  @Prop(Object) message;

  @Prop(Function) openRatePopup;

  @Getter color;

  @Action unrateMessage;

  get isClient() {
    return equals('Client', this.message.sourceHistoryItem);
  }

  get isGoodRate() {
    return equals('Good', this.message.data.rate);
  }

  onRateMessage(rate) {
    this.openRatePopup(this.message.id, rate);
  }

  onUnrateMessage() {
    if (this.message.data.rateMessage) return;
    this.unrateMessage(this.message);
  }

  get rateMessage() {
    return this.message.data.rateMessage
      ? `${this.$t(
          this.isGoodRate ? 'Liked with a comment' : 'Disliked with a comment',
          {
            time: formatDate(this.message.data.rated, 'dd MMMM HH:mm'),
          },
        )}:
      <div class="text">${this.message.data.rateMessage}</div>`
      : this.$t(this.isGoodRate ? 'Liked' : 'Disliked', {
          time: formatDate(this.message.data.rated, 'dd MMMM HH:mm'),
        });
  }
}

export default Message;
