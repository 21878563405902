import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component
class AskQuestionBlock extends Vue {
  @Getter color;

  @Action openQuestions;

  @Action closeMenu;

  onOpen() {
    this.closeMenu();
    this.openQuestions();
  }
}

export default AskQuestionBlock;
