import isSameDay from 'date-fns/isSameDay';
import isSameYear from 'date-fns/isSameYear';
import isSameMonth from 'date-fns/isSameMonth';
import dateTime from './dateTime';

export function isShortBookingDate([departureDateTime, returnDateTime]) {
  if (!departureDateTime) {
    return false;
  }
  const departureDateObj = new Date(departureDateTime);
  const returnDateObj = new Date(returnDateTime);
  if (!returnDateTime || isSameDay(departureDateObj, returnDateObj)) {
    return true;
  }
  if (!isSameYear(departureDateObj, returnDateObj)) {
    return false;
  }
  return isSameMonth(departureDateObj, returnDateObj);
}

export function bookingDate([departureDateTime, returnDateTime]) {
  if (!departureDateTime) {
    return null;
  }
  const departureDateObj = new Date(departureDateTime);
  const returnDateObj = new Date(returnDateTime);
  if (!returnDateTime || isSameDay(departureDateObj, returnDateObj)) {
    return dateTime(departureDateTime, 'd MMMM yyyy');
  }
  if (!isSameYear(departureDateObj, returnDateObj)) {
    return `${dateTime(departureDateTime, 'd MMMM yyyy')} — ${dateTime(
      returnDateTime,
      'd MMMM yyyy',
    )}`;
  }
  if (isSameMonth(departureDateObj, returnDateObj)) {
    return `${dateTime(departureDateTime, 'd')}-${dateTime(
      returnDateTime,
      'd MMMM yyyy',
    )}`;
  }
  return `${dateTime(departureDateTime, 'd MMMM')} — ${dateTime(
    returnDateTime,
    'd MMMM yyyy',
  )}`;
}
