import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f95ff708&scoped=true"
import script from "./Questions.js?vue&type=script&lang=js&external"
export * from "./Questions.js?vue&type=script&lang=js&external"
import style0 from "./styles.scss?vue&type=style&index=0&id=f95ff708&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f95ff708",
  null
  
)

export default component.exports