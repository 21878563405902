import { keyBy, map, get, getOr } from 'lodash/fp';
import { getBookings, getBooking, getBookingByPnr } from '../../api/bookings';
import { FETCH_LOGOUT_SUCCESS } from './auth';

const FETCH_BOOKINGS_REQUEST = 'FETCH_BOOKINGS_REQUEST';
const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS';
const FETCH_BOOKINGS_FAILURE = 'FETCH_BOOKINGS_FAILURE';

const FETCH_BOOKING_REQUEST = 'FETCH_BOOKING_REQUEST';
const FETCH_BOOKING_SUCCESS = 'FETCH_BOOKING_SUCCESS';
const FETCH_BOOKING_FAILURE = 'FETCH_BOOKING_FAILURE';

const HIDE_BOOKING_LOADER = 'HIDE_BOOKING_LOADER';

const initialState = {
  allIds: [],
  byId: {},
  bookingsLoading: false,
  bookingLoading: true,
  currentBooking: {},
  error: '',
};

const getters = {
  bookings: ({ allIds, byId }) => map((id) => byId[id], allIds),
  booking: get('currentBooking'),
  bookingsLoading: get('bookingsLoading'),
  bookingLoading: get('bookingLoading'),
  bookingError: get('error'),
};

const mutations = {
  [FETCH_BOOKINGS_REQUEST](state) {
    state.bookingsLoading = true;
  },
  [FETCH_BOOKINGS_FAILURE](state) {
    state.bookingsLoading = false;
  },
  [HIDE_BOOKING_LOADER](state) {
    state.bookingLoading = false;
  },
  [FETCH_BOOKINGS_SUCCESS](state, { allIds, byId }) {
    state.allIds = allIds;
    state.byId = byId;
    state.bookingsLoading = false;
  },
  [FETCH_BOOKING_SUCCESS](state, currentBooking) {
    state.currentBooking = currentBooking;
    state.bookingLoading = false;
  },
  [FETCH_BOOKING_REQUEST](state) {
    state.error = '';
    state.bookingLoading = true;
  },
  [FETCH_BOOKING_FAILURE](state, error) {
    state.bookingLoading = false;
    state.error = error;
  },
  [FETCH_LOGOUT_SUCCESS](state) {
    state.allIds = [];
    state.byId = {};
    state.currentBooking = {};
  },
};
const actions = {
  fetchBookings: async ({ commit, state }) => {
    if (!state.allIds.length) {
      commit(FETCH_BOOKINGS_REQUEST);
    }
    try {
      const res = await getBookings();
      const allIds = map('id', res.data.bookings);
      const byId = keyBy('id', res.data.bookings);
      commit(FETCH_BOOKINGS_SUCCESS, { allIds, byId });
      return res;
    } catch (e) {
      commit(FETCH_BOOKINGS_FAILURE, getOr(500, 'response.status')(e));
      throw e;
    }
  },

  fetchBooking: async ({ commit, state }, payload) => {
    commit(FETCH_BOOKING_REQUEST);
    try {
      const res = await getBooking(payload);
      commit(FETCH_BOOKING_SUCCESS, res.data);
      if (!state.allIds.length) {
        commit(FETCH_BOOKINGS_SUCCESS, {
          allIds: [res.data.id],
          byId: { [res.data.id]: res.data },
        });
      }
      return res;
    } catch (e) {
      commit(FETCH_BOOKING_FAILURE, getOr(500, 'response.status')(e));
      throw e;
    }
  },
  fetchBookingByPnr: async ({ commit, state }, payload) => {
    commit(FETCH_BOOKING_REQUEST);
    try {
      const res = await getBookingByPnr(payload);
      commit(FETCH_BOOKING_SUCCESS, res.data);
      if (!state.allIds.length) {
        commit(FETCH_BOOKINGS_SUCCESS, {
          allIds: [res.data.id],
          byId: { [res.data.id]: res.data },
        });
      }
      return res;
    } catch (e) {
      commit(FETCH_BOOKING_FAILURE, getOr(500, 'response.status')(e));
      throw e;
    }
  },
  hideBookingLoader: ({ commit }) => commit(HIDE_BOOKING_LOADER),
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
