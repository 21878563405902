import { map, get, keyBy, compose, filter } from 'lodash/fp';
import {
  getHistory,
  addMessage,
  rateMessage,
  unrateMessage,
} from '../../api/support';

const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';
const ADD_MESSAGE = 'ADD_MESSAGE';

const RATE_MESSAGE_REQUEST = 'RATE_MESSAGE_REQUEST';
const RATE_MESSAGE_SUCCESS = 'RATE_MESSAGE_SUCCESS';
const RATE_MESSAGE_FAILURE = 'RATE_MESSAGE_FAILURE';

const UNRATE_MESSAGE_SUCCESS = 'UNRATE_MESSAGE_SUCCESS';

const initialState = {
  isLoading: false,
  allIds: [],
  byId: {},
  isRating: false,
};

const getters = {
  messages: ({ allIds, byId }) =>
    compose(
      filter({ type: 'Message' }),
      map((id) => byId[id]),
    )(allIds),
  messagesLoading: get('isLoading'),
  isMessageRating: get('isRating'),
};

const mutations = {
  [FETCH_MESSAGES_REQUEST](state) {
    state.isLoading = true;
  },
  [FETCH_MESSAGES_FAILURE](state) {
    state.isLoading = false;
  },
  [FETCH_MESSAGES_SUCCESS](state, { allIds, byId }) {
    state.allIds = allIds;
    state.byId = byId;
    state.isLoading = false;
  },
  [ADD_MESSAGE](state, message) {
    state.allIds.push(message.id);
    state.byId[message.id] = message;
  },
  [RATE_MESSAGE_REQUEST](state) {
    state.isRating = true;
  },
  [RATE_MESSAGE_FAILURE](state) {
    state.isRating = false;
  },
  [RATE_MESSAGE_SUCCESS](state, message) {
    state.isRating = false;
    state.byId[message.id] = message;
  },
  [UNRATE_MESSAGE_SUCCESS](state, message) {
    state.byId[message.id] = message;
  },
};

const actions = {
  fetchMessages: async ({ commit }, { id, silent = false }) => {
    if (!silent) {
      commit(FETCH_MESSAGES_REQUEST);
    }
    try {
      const res = await getHistory(id);
      const allIds = map('id', res.data);
      const byId = keyBy('id', res.data);
      commit(FETCH_MESSAGES_SUCCESS, { allIds, byId });

      return res;
    } catch (e) {
      commit(FETCH_MESSAGES_FAILURE);
      throw e;
    }
  },
  addMessage: async ({ commit }, data) => {
    const res = await addMessage(data);
    commit(ADD_MESSAGE, res.data);
  },
  rateMessage: async ({ commit }, { data, showLoader }) => {
    if (showLoader) {
      commit(RATE_MESSAGE_REQUEST);
    }
    try {
      const res = await rateMessage(data);
      commit(RATE_MESSAGE_SUCCESS, res.data);

      return res;
    } catch (e) {
      commit(RATE_MESSAGE_FAILURE);
      throw e;
    }
  },
  unrateMessage: async ({ commit }, message) => {
    unrateMessage(message.id);
    commit(UNRATE_MESSAGE_SUCCESS, {
      ...message,
      data: {
        ...message.data,
        rate: '',
        rateMessage: null,
      },
    });
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
