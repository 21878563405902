import { each, some, indexOf, remove, equals, getOr } from 'lodash/fp';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action, Mutation } from 'vuex-class';
import validateEmail from '@/helpers/validateEmail';
import { askAccessByPinCode } from '../../../api/auth';
import { KEY_CODE, VALID_FILE_EXTENSIONS } from '../../../constants';
import ConfirmCode from '../ConfirmCode';

@Component({
  components: {
    ConfirmCode,
  },
})
class NewQuestion extends Vue {
  @Prop(Function) close;

  @Prop(Function) onSend;

  @Getter isAuth;

  @Getter color;

  @Getter bookings;

  @Getter booking;

  @Getter issueCategories;

  @Getter settings;

  @Getter user;

  @Getter isConfirmedUser;

  @Getter confirmationRequiredWithBooking;

  @Getter confirmationRequired;

  @Action fetchIssueCategories;

  @Action addIssue;

  @Action logout;

  @Mutation('TOGGLE_FILE_DIALOG') toggleFileDialog;

  email = '';

  selectedBooking = {};

  question = '';

  issueCategory = '';

  filesToUpload = [];

  formData = new FormData();

  isSending = false;

  fileError = '';

  isCodeWaiting = false;

  emailError = false;

  isLogoutPopupOpen = false;

  get isEmailValid() {
    return validateEmail(this.email);
  }

  get tabindex() {
    return this.isCodeWaiting ? -1 : 1;
  }

  onEscKeyPress(evt) {
    if (evt.keyCode === KEY_CODE.ESC) {
      if (this.isLogoutPopupOpen) {
        this.cancelLogout();
        return;
      }
      this.close();
    }
  }

  async closeConfirmPopup() {
    await this.sendIssue();
    this.isCodeWaiting = false;
  }

  mounted() {
    this.selectedBooking =
      window.innerWidth <= 768 ? this.booking.id : this.booking;
    this.fetchIssueCategories();
    this.email = getOr('', 'ContextEmail')(this.user);
    if (!this.email) {
      this.$refs.email.focus();
    }
    document.addEventListener('keyup', this.onEscKeyPress);
  }

  hideError() {
    this.fileError = '';
  }

  filesChange(e) {
    const fileList = e.target.files;
    this.fileError = '';
    this.filesToUpload = [];
    this.toggleFileDialog(false);
    each((file) => {
      const item = {
        name: file.name,
        file,
      };
      const ext = file.name.split('.').pop().toLowerCase();

      if (equals(-1, indexOf(ext, VALID_FILE_EXTENSIONS))) {
        this.fileError = this.$t('invalid_file_type_only_allowed', {
          0: VALID_FILE_EXTENSIONS.join(', ').toUpperCase(),
        });
        return;
      }

      if (file.size > this.settings.attachFileMaxSizeMb * 1e6) {
        this.fileError = `${this.$t('the_maximum_allowed_file_size_is')} ${
          this.settings.attachFileMaxSizeMb
        }Mb`;
        return;
      }
      if (!some(item, this.filesToUpload)) {
        this.filesToUpload.push(item);
      }
    }, fileList);
    e.target.value = '';
  }

  removeFile(item) {
    this.filesToUpload = remove(item, this.filesToUpload);
    this.$refs.file.value = '';
  }

  async sendIssue() {
    const isSelectedBooking =
      typeof this.selectedBooking === 'object'
        ? this.selectedBooking.id
        : this.selectedBooking;
    if (!this.question && !this.filesToUpload.length) {
      return false;
    }
    this.isSending = true;

    this.formData.append(
      'firstMessage',
      this.question.replace(/(?:\r\n|\r|\n)/g, '<br/>'),
    );
    if (isSelectedBooking) {
      this.formData.append('bookingId', isSelectedBooking);
    } else {
      this.formData.append('email', this.email);
    }
    if (this.issueCategory) {
      this.formData.append(
        'issueCategoryId',
        typeof this.issueCategory === 'object'
          ? this.issueCategory.value
          : this.issueCategory,
      );
    }
    each(({ file, name }) => {
      this.formData.append('attachments', file, name);
    }, this.filesToUpload);

    try {
      const res = await this.addIssue({
        data: this.formData,
        withBooking: isSelectedBooking,
      });
      this.onSend();
      return res;
    } catch (e) {
      this.isSending = false;
      // this.fileError = `${this.$t('the_maximum_allowed_file_size_is')} ${
      //   this.settings.attachFileMaxSizeMb
      // }Mb`;
      throw e;
    }
  }

  get disabled() {
    return (
      (!this.question && !this.filesToUpload.length) ||
      !this.issueCategory ||
      (!this.isAuth && !this.isEmailValid)
    );
  }

  async requestPinCode() {
    this.isSending = true;
    try {
      await askAccessByPinCode({ email: this.email });
      this.isCodeWaiting = true;
    } finally {
      this.isSending = false;
    }
  }

  onSubmit() {
    if (!this.user) {
      if (this.confirmationRequired) {
        this.requestPinCode();
      } else {
        this.sendIssue();
      }
      return;
    }
    if (!this.isConfirmedUser && this.confirmationRequired) {
      this.requestPinCode();
      return;
    }
    this.sendIssue();
  }

  validateEmail() {
    if (this.email && !this.isEmailValid) {
      this.emailError = true;
    }
  }

  onEmailChange($event) {
    this.email = $event.target.value.toLowerCase();
    this.emailError = false;
  }

  onLogout() {
    this.isLogoutPopupOpen = true;
  }

  cancelLogout() {
    this.isLogoutPopupOpen = false;
  }

  successLogout() {
    this.logout('newissue=true');
  }

  beforeDestroy() {
    clearTimeout(this.timeout);
    document.removeEventListener('keyup', this.onEscKeyPress);
  }
}

export default NewQuestion;
