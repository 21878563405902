<template>
  <footer class="footer">
    <div class="container">
      <div class="inner">
        <div class="copyright">© {{ brandName }}, {{ year }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: ['brandName'],
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style src="./index.scss" lang="scss" scoped></style>
