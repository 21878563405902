import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import VueChatScroll from 'vue-chat-scroll';
import VTooltip from 'v-tooltip';
import Vuebar from 'vuebar';
import VueTextareaAutosize from 'vue-textarea-autosize';
import VueSmoothScroll from 'vue2-smooth-scroll';
import Scrollspy, { Easing } from 'vue2-scrollspy';
import VueScrollactive from 'vue-scrollactive';
import VBodyScrollLock from 'v-body-scroll-lock';
import vSelect from './components/UI/Select';
import App from './App';
import router from './router';
import store from './store';
import i18n from './i18n';
import {
  Spinner,
  Dropdown,
  Icon,
  AppInput,
  AppButton,
  AskQuestionBlock,
} from './components';
import { capitalize, dateTime, getLang, size, bookingDate } from './helpers';
import './assets/styles/_fonts.scss';

Vue.config.productionTip = false;

Vue.use(VBodyScrollLock);
Vue.use(VueSmoothScroll, {
  duration: 1500,
  updateHistory: false,
});
Vue.use(vClickOutside);
Vue.use(VueChatScroll);
Vue.use(Vuebar);
Vue.use(VueTextareaAutosize);
Vue.use(VTooltip, {
  disposeTimeout: 1000,
});
Vue.use(Scrollspy, {
  easing: Easing.Cubic.InOut,
});
Vue.use(VueScrollactive);

Vue.component('Dropdown', Dropdown);
Vue.component('Spinner', Spinner);
Vue.component('Icon', Icon);
Vue.component('AppInput', AppInput);
Vue.component('AppButton', AppButton);
Vue.component('v-select', vSelect);
Vue.component('AskQuestionBlock', AskQuestionBlock);

Vue.filter('capitalize', capitalize);
Vue.filter('dateTime', dateTime);
Vue.filter('lang', getLang);
Vue.filter('size', size);
Vue.filter('bookingDate', bookingDate);

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
