import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import differenceInHours from 'date-fns/differenceInHours';
import humanizeDuration from 'humanize-duration';
import i18n from '../i18n';

export default function calcDuration(
  startTime,
  endTime,
  units = ['y', 'mo', 'w', 'd', 'h', 'm', 's'],
  round = true,
) {
  const endDateObj = new Date(endTime);
  const startDateObj = new Date(startTime);
  const diff = differenceInMilliseconds(endDateObj, startDateObj);
  const defaultUnits = ['y', 'mo', 'w', 'd', 'h', 'm', 's'];
  const hours = differenceInHours(endDateObj, startDateObj);
  return humanizeDuration(diff, {
    language: i18n.locale,
    delimiter: ' ',
    conjunction: ` ${i18n.t('and')} `,
    largest: hours >= 24 ? 1 : 2,
    units: hours < 24 ? defaultUnits : units,
    round: hours >= 24 ? round : false,
  });
}
