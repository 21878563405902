import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'app-button',
})
class Button extends Vue {
  @Prop({ type: String, default: 'button' }) type;

  @Prop({ type: String, default: 'primary' }) theme;

  @Prop({ type: String, default: 'md' }) size;

  @Prop({ type: Boolean, default: false }) isLoading;

  @Prop({ type: Boolean, default: false }) disabled;

  @Prop({ type: Boolean, default: false }) isBlock;

  @Prop({ type: Boolean, default: false }) isRounded;

  @Prop({ type: Boolean, default: false }) icon;

  @Prop({ type: Boolean, default: false }) focusable;

  @Getter color;

  get classNames() {
    return [
      'btn',
      `btn_${this.theme}`,
      this.icon && 'icon',
      this.color,
      this.size,
      this.isBlock && 'block',
      this.isRounded && 'round',
      this.focusable && 'focusable',
    ];
  }

  onClick(e) {
    this.$emit('click', e);
  }
}

export default Button;
