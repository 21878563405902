import { get, compose, split } from 'lodash/fp';
import * as API from '../../api/ui';
import { COLOR_SCHEME } from '../../constants';
import i18n from '../../i18n';

export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

const OPEN_QUESTIONS = 'OPEN_QUESTIONS';
const CLOSE_QUESTIONS = 'CLOSE_QUESTIONS';

const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';
const CLEAN_ERRORS = 'FETCH_SETTINGS_FAILURE';
const FETCH_LOCALE_SUCCESS = 'FETCH_LOCALE_SUCCESS';

const initialState = {
  isMenuOpen: false,
  settings: {},
  isQuestionsOpen: false,
  errors: [],
  locale: localStorage.getItem('locale') || 'sv-se',
  localeFetching: false,
};

const getters = {
  isMenuOpen: get('isMenuOpen'),
  settings: get('settings'),
  color: get('settings.colorScheme'),
  logo: get('settings.logoUrl'),
  brandName: get('settings.brandName'),
  isQuestionsOpen: get('isQuestionsOpen'),
  errors: get('errors'),
  isLocaleFetching: get('localeFetching'),
  locale: compose(get(0), split('-'), get('locale')),
  culture: get('locale'),
  defaultLocale: get('settings.systemDefaultLanguage'),
  supportedCultures: get('settings.supportedCultures'),
  confirmationRequired: get(
    'settings.confirmationRequiredForIssuesWithoutBooking',
  ),
  confirmationRequiredWithBooking: get(
    'settings.confirmationRequiredForIssuesWithBooking',
  ),
};

const mutations = {
  [OPEN_MENU](state) {
    state.isMenuOpen = true;
  },
  [CLOSE_MENU](state) {
    state.isMenuOpen = false;
  },
  [FETCH_SETTINGS_REQUEST]() {},
  [FETCH_SETTINGS_FAILURE]() {},
  [FETCH_SETTINGS_SUCCESS](state, data) {
    state.settings = data;
    state.settings.color = COLOR_SCHEME[data.colorScheme];
  },
  [OPEN_QUESTIONS](state) {
    state.isQuestionsOpen = true;
  },
  [CLOSE_QUESTIONS](state) {
    state.isQuestionsOpen = false;
  },
  [FETCH_LOCALE_SUCCESS](state) {
    state.localeFetching = true;
  },
  [FETCH_LOCALE_SUCCESS](state, locale) {
    state.locale = locale;
    state.localeFetching = false;
  },
};

const actions = {
  openMenu: ({ commit }) => commit(OPEN_MENU),
  closeMenu: ({ commit }) => commit(CLOSE_MENU),
  openQuestions: ({ commit }) => {
    document.querySelector('body').classList.add('fixed');
    commit(OPEN_QUESTIONS);
  },
  closeQuestions: ({ commit }) => {
    document.querySelector('body').classList.remove('fixed');
    commit(CLOSE_QUESTIONS);
  },
  cleanErrors: ({ commit }) => commit(CLEAN_ERRORS),
  fetchSettings: async ({ commit }) => {
    commit(FETCH_SETTINGS_REQUEST);
    try {
      const browserLocale = localStorage.getItem('locale');
      const res = await API.getSettings();
      if (!browserLocale) {
        [i18n.locale] = res.data.systemDefaultLanguage.split('-');
      }
      commit(FETCH_SETTINGS_SUCCESS, res.data);
      return res;
    } catch (e) {
      commit(FETCH_SETTINGS_FAILURE);
      throw e;
    }
  },
  fetchLocale: async ({ commit, getters }) => {
    try {
      const res = await API.getLocale();
      [i18n.locale] = res.data.split('-');
      commit(FETCH_LOCALE_SUCCESS, res.data);
      return res;
    } catch (e) {
      [i18n.locale] = getters.defaultLocale.split('-');
      throw e;
    }
  },
  setLocale: async ({ commit }, culture) => {
    const res = await API.updateLocale({ culture });
    commit(FETCH_LOCALE_SUCCESS, culture);
    localStorage.setItem('locale', culture);
    return res;
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
