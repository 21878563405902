import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1ab2dc8e&scoped=true"
import script from "./spinner.js?vue&type=script&lang=js&external"
export * from "./spinner.js?vue&type=script&lang=js&external"
import style0 from "./styles.scss?vue&type=style&index=0&id=1ab2dc8e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab2dc8e",
  null
  
)

export default component.exports