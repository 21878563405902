import api from './config';

export function addIssue(data) {
  return api.post('/Support/Issue', data, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
}
export function addIssueWithoutBooking(data) {
  return api.post('/Support/IssueWithoutBooking', data, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
}
export function addMessage(data) {
  return api.post('/Support/Message', data, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
}
export function getIssue(id) {
  return api(`/Support/Issue/${id}`);
}
export function getAttachment(id) {
  return api(`/Support/Attachment/${id}`);
}
export function getHistory(id) {
  return api(`/Support/history/${id}`);
}
export function getIssueCategories() {
  return api('/Support/IssueCategories');
}
export function getIssues(page = 1) {
  return api(`/Support/issuespage/${page}`);
}
export function getIssuesCount() {
  return api('/Support/issuescount');
}

export function getNewMessageCount() {
  return api('/Support/IssuesWithNewMessageCount');
}

export function markReadedIssue(issueid) {
  return api.post('/Support/IssueReaded', { issueid });
}

export function rateMessage(data) {
  return api.post('/Support/issuemessagerate', data);
}
export function unrateMessage(messageId) {
  return api.delete(`/Support/issuemessagerate/${messageId}`);
}
