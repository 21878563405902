export const UPDATE_DELAY = 15000;

export const KEY_CODE = {
  ESC: 27,
};

export const COLOR_SCHEME = {
  Red: '#FF908E',
  Blue: '#449AF7',
};

export const LANGUAGE = {
  en: 'English',
  sv: 'Sverige',
};

export const VALID_FILE_EXTENSIONS = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'pdf',
  'bmp',
];

export const EXCLUDE_KEYS = [
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
  'Enter',
];

export const LOCALES = new Set(['en', 'sv']);
