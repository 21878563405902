import axios from 'axios';

const BASE_URL =
  process.env.NODE_ENV !== 'production'
    ? // 'https://client.rcg.traveknowledgy.com/api'
      'https://client.flightsearch.se/api'
    : '/api';

// eslint-disable-next-line import/no-mutable-exports
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  },
});

export default api;
