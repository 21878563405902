import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '',
      name: 'main',
      component: () => import('./views/Booking'),
    },
    {
      path: '/booking/:bookingId?',
      name: 'booking',
      component: () => import('./views/Booking'),
    },
    {
      path: '/pnr-booking',
      name: 'pnr-booking',
      component: () => import('./views/PnrBooking'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login'),
    },
    {
      path: '/old-login',
      name: 'old-login',
      component: () => import('./views/OldLogin'),
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: () => import('./views/NotFound'),
    },
  ],
});

export default router;
