<template>
  <div id="app">
    <div class="outdated-popup" v-if="isOutdatedBrowser">
      <h6>
        {{ $t('outdated_title') }}
      </h6>
      <p>
        {{ $t('outdated_text') }}
      </p>
      <a href="http://outdatedbrowser.com/">
        {{ $t('outdated_button') }}
      </a>
    </div>
    <SvgSprite />
    <div class="outdated" v-if="isOutdatedBrowser">
      <Icon name="sad" width="80" height="34" />
    </div>
    <div class="wrapper" v-else>
      <Header />
      <transition name="opacity">
        <div class="overlay" v-if="isMenuOpen" @click="closeMenu"></div>
      </transition>
      <Sidebar />
      <Spinner v-if="isAppLoading" />
      <router-view v-else />
      <Footer :brandName="brandName" />
      <Questions v-if="isQuestionsOpen && !isAppLoading && !bookingLoading" />
      <div class="questionBtnWrapper" v-if="isAuth || booking">
        <span class="questionBtnHint" v-if="isShowMessage">{{
          $t('Ask your question here')
        }}</span>
        <div
          :class="['questionBtn', color, { isPulse }]"
          @click="openQuestions"
        >
          <span v-if="newMessageCount">{{ newMessageCount }}</span>
          <Icon name="conversation" width="26" height="26" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { equals, get } from 'lodash/fp';
import qs from 'query-string';
import { UPDATE_DELAY } from '@/constants';
import { Questions, SvgSprite, Sidebar, Footer, Header } from '@/components';

const supportsPromise =
  typeof Promise !== 'undefined' &&
  Object.prototype.toString.call(Promise.resolve()) === '[object Promise]';

@Component({
  components: {
    Sidebar,
    Header,
    SvgSprite,
    Footer,
    Questions,
  },
})
class App extends Vue {
  @Getter booking;

  @Getter isMenuOpen;

  @Getter isAuth;

  @Getter isQuestionsOpen;

  @Getter color;

  @Getter brandName;

  @Getter newMessageCount;

  @Getter isConfirmedUser;

  @Getter bookingLoading;

  @Getter user;

  @Action fetchLocale;

  @Action getUser;

  @Action loginByMagicLink;

  @Action fetchSettings;

  @Action openQuestions;

  @Action fetchNewMessageCount;

  @Action closeMenu;

  @Action fetchFaqs;

  isAppLoading = true;

  interval = null;

  isOutdatedBrowser = false;

  isPulse = true;

  isShowMessage = false;

  async checkAuth() {
    const { MagicGuid, pnr } = qs.parse(window.location.search);
    if (!pnr && MagicGuid) {
      return this.loginByMagicLink(MagicGuid);
    }
    const { pathname } = window.location;
    if (!equals('/pnr-booking', pathname)) {
      const res = await this.getUser();
      const pnr = get('Pnr', res.data);
      if (pnr && !equals('*', pnr)) {
        this.$router.push({
          path: 'pnr-booking',
          query: { email: this.user.User, pnr },
        });
      } else {
        this.$router.push('/login');
      }
      return res;
    }
    return Promise.resolve();
  }

  async mounted() {
    const { pathname } = window.location;
    try {
      await this.fetchSettings();
      await this.checkAuth();
      await this.fetchLocale();
    } catch {
      if (!equals('/pnr-booking', pathname) && !equals('/login', pathname)) {
        this.$router.push('/login');
      }
    } finally {
      this.isAppLoading = false;
    }

    if (!supportsPromise) {
      this.isOutdatedBrowser = true;
      return;
    }

    const { $route } = this;
    const { query } = $route;
    if (query.issueid || query.newissue || query.askQuestion) {
      this.openQuestions();
    }
    document.title = this.brandName;
    setTimeout(() => {
      this.isShowMessage = true;
    }, 1500);
    setTimeout(() => {
      this.isPulse = false;
    }, 5000);
    setTimeout(() => {
      this.isShowMessage = false;
    }, 6000);
    setInterval(() => {
      this.isPulse = true;
      setTimeout(() => {
        this.isPulse = false;
      }, 5000);
    }, 30000);
  }

  pollNewMessages() {
    this.interval = setTimeout(async () => {
      try {
        await this.fetchNewMessageCount();
      } finally {
        this.pollNewMessages();
      }
    }, UPDATE_DELAY);
  }

  updated() {
    if (this.isQuestionsOpen) {
      this.interval = null;
    }
    if (
      !this.isQuestionsOpen &&
      (this.isConfirmedUser || this.booking.haveSupportIssue) &&
      !this.interval
    ) {
      this.fetchNewMessageCount();
      this.pollNewMessages();
    }
  }
}

export default App;
</script>

<style lang="scss">
@import './assets/styles/normalize';
/*@import './assets/styles/common';*/
/*@import './assets/styles/vendors/select';*/
/*@import './assets/styles/form';*/
/*@import './assets/styles/vb';*/
/*@import './assets/styles/global';*/
/*@import './assets/styles/tooltip';*/
.wrapper {
  height: 100%; /* for IE to behave */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
  flex: 1;
  @include mobile {
    height: 100%; /* for IE to behave */
    min-height: 100%;
    padding-top: 70px;
  }
}
.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1001;
}

.opacity-enter {
  opacity: 0;
}
.opacity-enter-active {
  transition: opacity 0.5s;
}
.opacity-leave-active {
  transition: opacity 0.5s;
  opacity: 0;
}
.pd {
  padding-top: 130px;
}
.questionBtnWrapper {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 1000;
}
.questionBtn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(#b8abe1, 0.24);
  &.isPulse {
    animation: pulse 1.5s infinite;
  }
  &:hover {
    animation: none;
  }
  & > span {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background: $pink;
    position: absolute;
    right: -2px;
    top: -2px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  &.Red {
    background: $red;
  }
  &.Yellow {
    background: $yellow;
  }
  &.Brown {
    background: $brown;
  }
  &.Green {
    background: $green;
  }
  &.Aqua {
    background: $aqua;
  }
  &.Violet {
    background: $violet;
  }
}
.questionBtnHint {
  position: absolute;
  background: #fff;
  font-size: 15px;
  right: 70px;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
  text-align: center;
  padding: 8px 10px;
  @include mobile {
    display: none;
  }
}
.slide-enter {
  transform: translateX(-110%);
}
.slide-enter-active {
  transition: transform 0.5s;
}
.slide-leave-active {
  transition: transform 0.5s;
  transform: translateX(-110%);
}
.outdated {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outdated-popup {
  color: #fff;
  background: #f25648;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 170px;
  text-align: center;
  text-transform: uppercase;
  z-index: 1500;
  h6 {
    font-size: 25px;
    margin: 30px 0 10px;
  }
  p {
    font-size: 12px;
  }
  a {
    display: block;
    padding: 10px 20px;
    margin: 30px auto 0;
    width: 260px;
    color: #ffffff;
    text-decoration: none;
    border: 2px solid #ffffff;
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(#b8abe1, 0);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(#b8abe1, 0);
  }
}
</style>
