import api from './config';

export function fetchFaqTopics(bookingId) {
  return api('/Support/faqtopicheaders', {
    ...(bookingId && { params: { bookingId } }),
  });
}

export function fetchFaqTopic(id, bookingId) {
  return api(`/Support/faqtopic/${id}`, { params: { bookingId } });
}
