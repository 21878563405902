import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'spinner',
})
class Spinner extends Vue {
  @Prop({ type: String, default: 'md' }) size;

  @Prop({ type: String, default: '' }) type;

  @Getter color;

  get classNames() {
    return ['spinner', this.size, this.type, this.color];
  }
}

export default Spinner;
