import { map, get, keyBy } from 'lodash/fp';
import getTickets from '../../api/tickets';
import { FETCH_LOGOUT_SUCCESS } from './auth';

const FETCH_TICKETS_REQUEST = 'FETCH_TICKETS_REQUEST';
const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
const FETCH_TICKETS_FAILURE = 'FETCH_TICKETS_FAILURE';

const initialState = {
  isLoading: false,
  allIds: [],
  byId: {},
};

const getters = {
  tickets: ({ byId }) => byId,
  ticketsLoading: get('isLoading'),
};

const mutations = {
  [FETCH_TICKETS_REQUEST](state) {
    state.isLoading = true;
  },
  [FETCH_TICKETS_FAILURE](state) {
    state.isLoading = false;
  },
  [FETCH_TICKETS_SUCCESS](state, { allIds, byId }) {
    state.allIds = allIds;
    state.byId = byId;
    state.isLoading = false;
  },
  [FETCH_LOGOUT_SUCCESS](state) {
    state.isLoading = false;
    state.allIds = [];
    state.byId = {};
  },
};

const actions = {
  fetchTickets: async ({ commit }, params) => {
    commit(FETCH_TICKETS_REQUEST);
    try {
      const res = await getTickets(params);
      const allIds = map('id', res.data);
      const byId = keyBy('id', res.data);
      commit(FETCH_TICKETS_SUCCESS, { allIds, byId });
      return res;
    } catch (e) {
      commit(FETCH_TICKETS_FAILURE);
      throw e;
    }
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
