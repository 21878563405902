import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { QuestionItem } from '..';
import PopularItem from './PopularQuestions/PopularItem';
import PopularQuestion from './PopularQuestion';
import NewQuestion from './NewQuestion';
import Success from './Success';
import MyQuestions from './MyQuestions';
import PopularQuestions from './PopularQuestions';
import Conversation from './Conversation';

@Component({
  name: 'questions',
  components: {
    QuestionItem,
    PopularItem,
    PopularQuestion,
    NewQuestion,
    Success,
    MyQuestions,
    PopularQuestions,
    Conversation,
  },
})
class Questions extends Vue {
  @Getter color;

  @Getter issues;

  @Getter issuesLoading;

  @Action closeQuestions;

  @Action fetchFaqs;

  @Getter booking;

  @Getter isConfirmedUser;

  @Getter confirmationRequiredWithBooking;

  @Getter faqs;

  selectedTab = 'popular';

  faqId = null;

  newQuestion = false;

  success = false;

  openedIssueId = null;

  get mustEnterCode() {
    return (
      this.booking.haveSupportIssue &&
      !this.isConfirmedUser &&
      this.confirmationRequiredWithBooking
    );
  }

  get askNewQuestion() {
    return !this.newQuestion && !this.openedIssueId && !this.mustEnterCode;
  }

  async mounted() {
    const { $route, booking, fetchFaqs } = this;
    const { query } = $route;
    if (query.issueid) {
      this.openConversation(query.issueid);
    }
    if (query.askQuestion) {
      this.selectedTab = 'my';
    }
    try {
      await fetchFaqs(booking.id);
      if (!this.faqs.length) {
        this.selectedTab = 'my';
      }
    } catch (e) {
      this.selectedTab = 'my';
    }
    if (query.newissue) {
      this.onNewQuestion();
    }
  }

  switchTab(tab) {
    this.selectedTab = tab;
  }

  openFaq(faqId) {
    this.faqId = faqId;
    this.newQuestion = false;
    this.selectedTab = null;
  }

  removeIssueIdFromPath() {
    const { query, name } = this.$route;
    const { issueid, ...withoutIssue } = query;
    this.$router.replace({ name, query: withoutIssue });
  }

  onQuestionsClose() {
    this.closeQuestions();
    this.openedIssueId = null;
    this.removeIssueIdFromPath();
  }

  onNewQuestion() {
    this.newQuestion = true;
    this.faqId = null;
    this.selectedTab = null;
  }

  backToMyQuestions() {
    this.newQuestion = false;
    this.selectedTab = 'my';
  }

  backToPopularQuestions() {
    this.faqId = null;
    this.newQuestion = false;
    this.selectedTab = 'popular';
  }

  onSend() {
    this.success = true;
    setTimeout(() => {
      this.newQuestion = false;
    }, 50);
  }

  closeConversation() {
    this.selectedTab = 'my';
    this.openedIssueId = null;
    this.removeIssueIdFromPath();
  }

  openConversation(issueId) {
    this.openedIssueId = issueId.toString();
    this.selectedTab = null;
  }

  get isPopularTab() {
    return this.selectedTab === 'popular';
  }
}

export default Questions;
